import axios from "axios";
import { useEffect, useState } from "react";
import { useJsApiLoader } from "@react-google-maps/api";
import NewFooter from "../footer";
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";
import { brandsImg, statesOfIndia } from "../utils/dummydata";
import MapComponent from "../../components/Map/MapComponent";
import useWindowDimensions from "../../hooks/WindowDimension";
import { Helmet } from "react-helmet";
const SalonFinders = () => {
  const [selectedState, setSelectedState] = useState("");
  const [selectedSalon, setSelectedSalon] = useState("");
  const [salonsDetails, setSalonsDetails] = useState([]);
  const [activeMarker, setActiveMarker] = useState(null);
  const windowDimensions = useWindowDimensions();
  const [center, setCenter] = useState({ lat: 28.7, lng: 77.1 });
  const apiKey = process.env.REACT_APP_MAP_API_KEY;
  const navigate = useNavigate();
  const { isLoaded, loadError } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: apiKey,
  });

  const containerStyle = {
    width: "100%",
    height: "100%",
  };

  const indiaBounds = {
    north: 37.84,
    south: 8.4,
    east: 97.4,
    west: 68.1,
  };

  useEffect(() => {
    const fetchApi = async () => {
      try {
        const data = await axios.post(
          "https://smartsalon.in/api/suggestionForParlors",
          {
            stateName: selectedState,
            salonType: selectedSalon,
          }
        );
        console.log("salonsDetails", data);

        if (data?.data?.data?.data?.length > 0) {
          setSalonsDetails(data?.data?.data);
          setCenter({
            lat: data?.data?.data[0]?.latitude,
            lng: data?.data?.data[0]?.longitude,
          });
        } else {
          setSalonsDetails([]);
        }
      } catch (error) {
        console.error("Error fetching salon data:", error);
      }
    };
    fetchApi();
  }, [selectedSalon, selectedState]);

  const handleRedirect = (marker) => {
    let lat = marker?.geoLocation[1] || 28;
    let long = marker?.geoLocation[0] || 77;
    window.open(`http://www.google.com/maps?q=${lat},${long}`);
  };

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const salons = ["Monsoon", "Monsoon Pro", "Smart Salon"];
  const storeItemId = (itemId) => {
    localStorage.setItem("itemID", itemId);
  };

  const handleSingleSalon = async (item) => {
    navigate(`${item?._id}?address=${item.address2}`);

    console.log("itemcoming", item);
    // to={`/products/${item.shortUrl}`}
  };

  if (loadError) return <div>Error loading Google Maps API script!</div>;

  return (
    <>
      <Helmet>
       <script>
          {`
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-KDRLNKTM');
          `}
        </script>


        {/* Google Tag Manager (noscript) */}
         <noscript>
          {`<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-KDRLNKTM"
height="0" width="0" style="display:none;visibility:hidden"></iframe>`}
        </noscript>
      </Helmet>

      <div className="w-full h-screen">
        <h1 className="w-full py-6 px-3 text-black text-center font-bold text-xl   md:text-5xl mt-0 md:mt-6">
          FIND SMART SALON
        </h1>
        <div className="flex  md:my-6 flex-col md:flex-row justify-between w-full sm:w-[95%] mx-auto shadow-md h-[800px] md:h-[550px]">
          <div className="flex text-white flex-col w-full md:w-1/2 h-full bg-black">
            <div className="w-full h-[250px] md:h-auto">
              <h1 className="w-full py-6 px-3  text-center font-bold text-2xl font-mono">
                Locate the Nearest Salon
              </h1>
              <div className="w-full flex flex-col gap-3 md:flex-row justify-evenly items-center mb-3">
                <div className="w-[80%] md:w-[40%] max-w-xs mx-auto">
                  <select
                    id="state"
                    name="state"
                    value={selectedState}
                    onChange={(e) => setSelectedState(e.target.value)}
                    className="mt-1 block w-full pl-3 pr-10 py-2 text-black text-base border border-white focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                  >
                    <option value="">Select a state...</option>
                    {statesOfIndia?.map((state) => (
                      <option key={state} value={state}>
                        {state}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="w-[80%] md:w-[40%] max-w-xs mx-auto">
                  <select
                    id="salon"
                    name="salon"
                    value={selectedSalon}
                    onChange={(e) => setSelectedSalon(e.target.value)}
                    className="mt-1 block w-full pl-3 text-black pr-10 py-2 text-base focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md border border-white"
                  >
                    <option value="">Select Salon Type...</option>
                    {salons?.map((salon, index) => (
                      <option key={index} value={salon}>
                        {salon}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            {windowDimensions.width < 768 && (
              <div className="w-full h-[calc(50%-125px)]">
                {isLoaded && center && (
                  <MapComponent
                    center={center}
                    handleRedirect={handleRedirect}
                    setActiveMarker={setActiveMarker}
                    activeMarker={activeMarker}
                    results={salonsDetails.data}
                  />
                )}
              </div>
            )}
            <div className="w-full py-6 flex flex-col h-[calc(50%-125px)] md:h-full  gap-y-5 overflow-y-auto bg-[#edede9]">
              {salonsDetails?.data?.length > 0 ? (
                salonsDetails?.data?.map((item, index) => (
                  <div
                    key={index}
                    className="w-[90%] md:w-[70%] mx-auto shadow-2xl rounded-lg flex flex-col bg-white px-3 py-5 "
                  >
                    <h1 className="text-black text-xl font-semibold">
                      {item?.address}
                    </h1>
                    <h1 className="text-black text-md font-semibold">
                      {item?.address2}
                    </h1>
                    <h1 className="text-black text-sm font-medium">
                      {item?.stateName}
                    </h1>
                    <h1 className="text-black text-sm font-medium">
                      {item?.phoneNumber}
                    </h1>
                    <button
                      className="w-full text-center bg-black text-white text-xl font-semibold mt-3 py-3"
                      onClick={() => handleSingleSalon(item)}
                    >
                      Visit
                    </button>
                  </div>
                ))
              ) : (
                <div className="w-full flex justify-center items-center h-full gap-y-5 overflow-y-auto my-5 bg-[#edede9]">
                  <h1 className="text-black text-xl font-semibold">
                    No Salon Selected or Found
                  </h1>
                </div>
              )}
            </div>
          </div>
          {windowDimensions.width > 768 && (
            <div className="w-full md:w-1/2 h-full">
              {isLoaded && center && (
                <MapComponent
                  center={center}
                  handleRedirect={handleRedirect}
                  setActiveMarker={setActiveMarker}
                  activeMarker={activeMarker}
                  results={salonsDetails.data}
                />
              )}
            </div>
          )}
        </div>
        <h1 className="w-full py-6 px-3 text-black text-center font-bold text-2xl md:text-5xl mt-6">
          Smart Salon - Location Finder
        </h1>
        <div className="w-full px-4 md:px-32 font-sans text-lg text-black opacity-85">
          Elevate Beauty Haven, an elite name in the world of glamour, is
          committed to transcending standards to gift each customer a
          rejuvenating transformation. Elevate takes immense pride in being the
          cherished hair and beauty revitalization companion of innumerable
          devoted patrons across our expansive network of over 100 locations
          globally, including a strong presence in India. Entrust us with the
          magic of enhancing your aesthetics, enabling you to bask in leisure
          and confidence, whether it’s for an enchanting party, a vibrant
          festival, or a simply delightful date. Experience the unparalleled
          beauty voyage at Elevate Beauty Haven where every moment is about
          celebrating and elevating your unique allure. In need of a new look
          for the holiday season? Or perhaps you're looking to reinvent
          yourself. Then don't hesitate any longer; make an appointment at Smart
          Salon right away and watch as you emerge from the salon a new,
          improved person. Making an appointment at Smart Salon couldn't be
          simpler. In order for our stylists and specialists to better serve
          you, please fill out the brief form above. Afterward, an executive
          will contact you to confirm your appointment. Your process of
          metamorphosis has officially begun. Our Salon Finder makes it simple
          to find and schedule an appointment at a Smart Salon near you. Our
          goal is for you to leave our salon happy and feeling fabulous after
          we've given you a new look and answered all your questions about hair
          and makeup. Schedule your visit right away!
        </div>
        <h1 className="w-full py-6 px-3 text-black text-center font-bold text-2xl md:text-5xl mt-6">
          Partner Brands
        </h1>
        <div className="mb-6">
          <Slider {...settings}>
            {brandsImg?.map((item, index) => {
              return (
                <div key={index}>
                  <img
                    src={item.brandUrl}
                    style={{ height: "130px", width: "200px" }}
                    alt="galleryimages"
                  />
                </div>
              );
            })}
          </Slider>
        </div>
        <NewFooter />
      </div>
    </>
  );
};

export default SalonFinders;
